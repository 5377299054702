<!-- 服务认证 -->
<template>
  <div>
    <el-card>
      <div v-if="isShow">
        <div style="color: #999">
          完成认证后，可以有效保障您的交易安全，且平台将依据法律规定及相关约定保护你的信息安全
        </div>
        <div class="contents">
          <div class="left">
            <div class="approve">
              <div>企业认证</div>
              <div class="noapprove">
                <i class="el-icon-info"></i>
                {{
                  companyStatus == 0
                    ? '未认证'
                    : companyStatus == 1
                    ? '审核中'
                    : companyStatus == 2
                    ? '已认证'
                    : '已拒绝'
                }}
              </div>
            </div>
            <div class="div2">完成认证后，可以有效保障您的交易安全</div>
            <div class="btn">
              <el-button v-if="authButton.promptlyAuthButtonByCompany">
                <router-link to="/firm">立即认证</router-link>
              </el-button>
              <el-button v-if="authButton.againAuthButtonByCompany">
                 <router-link to="/firm">重新认证</router-link>
              </el-button>
            </div>
          </div>
          <div class="right">
            <div class="approve">
              <div>车主认证</div>
              <div class="noapprove">
                <i class="el-icon-info"></i>
                {{
                  businessStatus == 0
                    ? '未认证'
                    : businessStatus == 1
                    ? '审核中'
                    : businessStatus == 2
                    ? '已认证'
                    : '已拒绝'
                }}
              </div>
            </div>
            <div class="div2">完成认证后，可以有效保障您的交易安全</div>
            <div class="btn">
              <el-button v-if="authButton.promptlyAuthButtonByBusiness">
                <router-link to="/car">立即认证</router-link>
              </el-button>
              <el-button v-if="authButton.againAuthButtonByBusiness">
                <router-link to="/car">重新认证</router-link>
              </el-button>
            </div>
          </div>
        </div>
        <div style="color: #999">
          *实名认证审核通过后可申请企业认证或加入已认证企业
        </div>
      </div>
      <div v-else>
        <router-view></router-view>
      </div>
    </el-card>
  </div>
</template>

<script>
import { getUser } from '@/api/user'

export default {
  components: {},
  data() {
    return {
      businessStatus: 0,
      companyStatus: 0,
      isShow: true,
      authButton: {}
    }
  },
  created() {
    getUser().then((res) => {
      this.businessStatus = res.data.businessStatus
      this.companyStatus = res.data.companyStatus
      this.authButton = res.data.authButton

    })
  },
  methods: {},
}
</script>
<style lang='scss' scoped>
.contents {
  display: flex;
  justify-content: space-between;
  padding: 20px 0;
  img {
    width: 100%;
    height: 200px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .left {
    width: 48%;
    background-image: url('../../../assets/images/firm.png');
    background-position: center;
    background-size: cover;
    text-align: left;
    padding: 50px 0 20px 30px;

  }
  .right {
    width: 48%;
    background-image: url('../../../assets/images/car.png');
    background-position: center;
    background-size: cover;
    text-align: left;
    padding: 50px 0 20px 30px;
  }
  .div2 {
    font-size: 14px;
    color: #999;
    margin-top: 20px;
  }
  .approve {
    display: flex;
    align-items: center;
    div:nth-child(1) {
      font-size: 18px;
    }
    .noapprove {
      margin-left: 20px;
      background-color: #fdf4df;
      color: #fcb110;
      padding: 2px 10px;
      border-radius: 50px;
      font-size: 14px;
      font-weight: bold;
    }
  }
  .btn {
    text-align: center;
    margin-top: 30px;
    .el-button {
      border: 1px solid #3c60bc;
      color: #3c60bc;
    }
  }
}
</style>